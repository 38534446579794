




































































































import Vue from 'vue'
import { ApiResponse, Fee, RequisitionItem, Product, Rate } from '@/interfaces'
import { mapState } from 'vuex'
import { AxiosRequestConfig } from 'axios'

interface ItemTotal {
  totalTax: string
  totalWithTax: string
  totalWithoutTax: string
}

export default Vue.extend({
  props: {
    item: {
      type: Object as () => RequisitionItem,
      required: false,
      default: () => {
        return {
          id: null,
          fees: [] as Fee[],
          requisition: null,
          vatRate: '' as string | null,
          endPrice: 0,
        }
      },
    },
    structureId: {
      type: Number,
      required: false,
      default: null,
    },
    vatRates: {
      type: Array,
      required: false,
      default: () => [] as Rate[],
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
    subjectToTax: {
      type: Boolean,
      required: false,
      default: null,
    },
    itemData: {
      type: Object as () => RequisitionItem,
      required: false,
      default: () => {
        return {
          dateOut: null,
        }
      },
    },
  },
  data() {
    return {
      busy: false,
      loading: false,
      products: [] as Product[],
      fees: [] as Fee[],
      storedId: null,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    item(newVal) {
      if (newVal.id && newVal.id !== this.storedId) {
        this.fees = JSON.parse(JSON.stringify(newVal.fees))
        this.loadFeeTotals()
        if (this.products.length === 0) {
          this.loadAllProducts()
        }
      }
    },
    structureId(newVal) {
      if (newVal && this.products.length === 0) {
        this.loadAllProducts()
      }
    },
  },
  mounted() {
    if (this.item) {
      this.fees = this.item?.fees ? JSON.parse(JSON.stringify(this.item.fees)) : []
      this.loadFeeTotals()
    }
  },
  methods: {
    isBuyerFee(fee: Fee) {
      return fee.type === 'fixe_buyer' || fee.type === 'percent_buyer'
    },
    isSellerFee(fee: Fee) {
      return fee.type === 'fixe_seller' || fee.type === 'percent_seller' || fee.type === 'frais'
    },
    canEditFee(index: number) {
      const fee = this.fees ? this.fees[index] : null
      return fee?.isEditable
    },
    addFee() {
      const listEditableFees = this.fees?.filter((fee) => fee.isEditable === true)
      if (this.fees.length > 0 && listEditableFees.length > 0) {
        this.fees.forEach((fee, index) => {
          if (!fee.label) {
            this.fees?.splice(index, 1)
          } else if (fee.isEditable) {
            this.saveFee(fee, index)
          }
        })
      }
      this.createNewFee()
    },
    createNewFee() {
      const itemsLength = this.fees?.length ?? 0
      const newFee: Fee = {
        label: '',
        type: null,
        quantity: 0,
        priceExcludingTaxes: '0',
        tvaRate: '0',
        priceIncludingTaxes: '',
        accountingAccount: '',
        isEditable: true,
        position: itemsLength + 1,
        opaque: null,
      }
      this.fees?.push(newFee)
    },
    clearFee(index: number) {
      const clearedFee: Fee = this.fees ? this.fees[index] : {}
      clearedFee.label = ''
      clearedFee.isEditable = true
      clearedFee.type = null
      this.fees?.splice(index, 1, clearedFee)
    },
    loadAllProducts() {
      if (!this.busy) {
        this.busy = true
        this.products = []
        this.$api
          .get('/products', {
            params: {
              page: 'requisitionItemForm',
              structure: this.structureId,
              type: this.type,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse
            const products = apiResponse.data

            if (products && Array.isArray(products)) {
              this.products = products
            }
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    loadProductInfo(index: number, searchProductId: number) {
      const selectedProduct = this.products.find((product) => product.id === searchProductId)
      if (selectedProduct) {
        const itemsLength = this.fees?.length ?? 0
        let quantity = 1
        if (selectedProduct.type === 'percent_seller' || selectedProduct.type === 'percent_buyer') {
          quantity = 0
          if (this.item.endPrice !== null && this.item.endPrice !== '') {
            quantity = parseFloat(this.item.endPrice?.toString() ?? '0')
          }
        }
        let fee = {
          label: selectedProduct?.name,
          type: selectedProduct?.type,
          quantity: quantity,
          priceExcludingTaxes: selectedProduct?.unitPriceExcludingTaxes,
          tvaRate: selectedProduct?.tvaRate,
          accountingAccount: selectedProduct?.accountingAccount,
          isEditable: true,
          isExpanded: false,
          position: itemsLength + 1,
          opaque: selectedProduct?.opaque,
        } as Fee
        if (this.fees && this.fees[index]) {
          if (this.fees[index].id) {
            fee = {
              ...fee,
              id: this.fees[index].id,
              quantity: this.fees[index].quantity,
            }
          }
          this.fees?.splice(index, 1, fee)
        } else {
          this.fees?.push(fee)
        }
        this.loadFeeTotals()
      }
    },
    changeFeeState(index: number, state: string) {
      if (this.fees) {
        const fee = this.fees[index]
        const hasEditableItem = this.fees?.filter((item) => item.isEditable === true)
        if (hasEditableItem.length > 0 && hasEditableItem[0] !== fee) return

        if (fee && !isNaN(fee.quantity ?? 0)) {
          if (state === 'isEditable' && !fee.isEditable) {
            fee.isEditable = true
          } else if (state === 'isNonEditable' && fee.isEditable) {
            fee.isEditable = false
            this.saveFee(fee, index)
          }
          this.fees?.splice(index, 1, fee)
        }
      }
    },
    loadFeeTotals(index: number | null = null) {
      if (this.fees.length === 0) return
      this.loading = true
      this.$api
        .post(`/item/${this.item?.id}/fee/update-total`, this.fees)
        .then((response) => {
          const apiResponse = response.data as ApiResponse
          this.getFeeTotals(apiResponse.data.items, index)
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateFeeTotals(index: number, data: Fee) {
      this.fees?.splice(index, 1, data)
      this.loadFeeTotals(index)
    },
    saveFee(fee: Fee, index: number) {
      if (!this.busy) {
        if (!fee.label) {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Veuillez renseigner le nom du frais.',
          })

          return
        }
        fee.itemId = this.item?.id
        const data = {
          ...fee,
        }

        let method = 'post'
        let url = `/fee/item/${this.item?.id}`
        if (fee.id) {
          method = 'put'
          url = `/fee/${fee.id}/item/${this.item?.id}`
        }

        this.$api
          .request({
            method,
            url,
            data,
          } as AxiosRequestConfig)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            const updatedFee = apiResponse.data.fee
            updatedFee.isEditable = false
            this.fees.splice(index, 1, updatedFee)
            this.getFeeTotals(apiResponse.data.totals.items)
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès',
            })
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse

              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    deleteFee(index: number, itemId: number) {
      if (!itemId) {
        this.fees?.splice(index, 1)
      } else {
        this.$confirm(
          'Êtes-vous sûr(e) de vouloir supprimer cette ligne de frais ? Attention, cette opération est irréversible.',
          'Confirmation',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Annuler',
            type: 'warning',
          }
        )
          .then(() => {
            const id = this.item.id
            this.busy = true
            this.$api.delete(`/fee/${itemId}/requisition-item/${id}`).then((response) => {
              const apiResponse = response.data as ApiResponse

              this.fees = apiResponse.data.fees
              this.getFeeTotals(apiResponse.data.totals.items)
              this.$notify({
                type: 'success',
                title: 'Succès',
                message: 'Opération réalisée avec succès !',
              })
            })
          })
          .catch((error) => {
            if (error !== 'cancel') {
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    getFeeTotals(itemTotals: ItemTotal[], index: number | null = null) {
      const fees = this.fees ?? []
      if (itemTotals?.length == 0 || !fees) return
      for (let i = 0; i < fees.length; i++) {
        if (!fees[i] || !itemTotals[i]) continue
        let fee = fees[i]
        fee.totalExcludingTaxes = itemTotals[i].totalWithoutTax
        fee.totalTaxes = itemTotals[i].totalTax
        fee.totalIncludingTaxes = itemTotals[i].totalWithTax
        if (index !== null && index === i) {
          if (this.fees && this.fees[index]) {
            this.fees?.splice(index, 1, fee)
          }
        } else {
          this.fees?.splice(i, 1, fee)
        }
      }
    },
  },
})
