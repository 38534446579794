

























































































































































































































































































































































































































































  import Vue from 'vue'
  import { ApiResponse, Contact, RequisitionItem, Structure } from '@/interfaces'
  import ContactForm from '@/components/ContactForm.vue'
  import { MessageBoxInputData } from 'element-ui/types/message-box'

  interface Notification {
    message: string
    type: 'error' | 'success'
  }

  interface ItemToCheck {
    item: boolean | string | null | undefined
    name: string
  }

  interface RequisitionProject {
    id?: number | string | null
    generatedNumber?: string | null
    structure?: Structure | null
  }

  export default Vue.extend({
    components: {
      ContactForm,
    },
    props: {
      item: {
        type: Object as () => RequisitionItem,
        required: false,
        default: () => {
          return {
            id: null,
            previousOwnerId: null,
            newOwnerId: null,
            ciPresent: true,
            numFormule: null,
            ciDate: null,
            opositionRight: null,
          }
        },
      },
      isDisabled: Boolean,
    },
    data() {
      return {
        busy: false,
        itemData: Object.assign({}, this.item ?? {}) as RequisitionItem,
        viewportWidth: 0,
        showAddContactModal: false,
        newContact: null as Contact | null,
        newContactType: '',
        newContactId: null as string | null,
        previousOwnerId: null,
        previousOwnerInfos: {},
        newOwnerInfos: {},
        showBusinessPreviousInfos: false,
        showBusinessNewInfos: false,
        newOwnerId: null,
        ciPresent: true,
        numFormule: null,
        ciDate: null,
        opositionRight: null,
        previousOwners: [] as Record<string, string | null>[],
        newOwners: [] as Record<string, string | null>[],
        changeDetected: null as boolean | null,
        showNotification: false,
        notification: {
          message: '',
          type: 'success',
        } as Notification,
        resetContactForm: false,
        isFormDisabled: false,
        tmsPassword: null as string | null,
      }
    },
    watch: {
      newContact(newVal) {
        if (newVal !== null) {
          switch (this.newContactType) {
            case 'previousOwner':
              this.updatePreviousOwner(newVal)
              break
            case 'newOwner':
              this.updateNewOwner(newVal)
              break
          }
          this.newContact = null
          this.newContactType = ''
        }
      },
      item: {
        deep: true,
        immediate: true,
        handler(newVal) {
          if (newVal) {
            this.itemData = Object.assign({}, newVal ?? {})

            // Set Ids for select
            this.itemData = {
              ...this.itemData,
              saleId: newVal.sale?.id?.toString() ?? null,
              previousOwnerId: newVal.previousOwner?.id?.toString() ?? null,
              newOwnerId: newVal.newOwner?.id?.toString() ?? null,
            }
          }

          // Set previous owner Id for select
          if (newVal.previousOwner?.id) {
            this.previousOwners = [
              {
                label: newVal.previousOwner.formName ?? '-',
                value: newVal.previousOwner.id.toString(),
              },
            ]
            this.previousOwnerInfos = this.setOwnerInfos(newVal.previousOwner, 'previousOwner')
          } else {
            this.previousOwnerInfos = this.setOwnerInfos(null, 'previousOwner')
          }

          // Set new owner Id for select
          if (newVal.newOwner?.id) {
            this.newOwners = [
              {
                label: newVal.newOwner.formName ?? '-',
                value: newVal.newOwner.id.toString(),
              },
            ]
            this.newOwnerInfos = this.setOwnerInfos(newVal.newOwner, 'newOwner')
          } else {
            this.newOwnerInfos = this.setOwnerInfos(null, 'newOwner')
          }
        },
      },
      itemData: {
        deep: true,
        immediate: true,
        handler(newVal) {
          this.$emit('updateData', newVal)
          this.changeDetected = this.changeDetected !== null
        },
      },
    },
    mounted() {
      if (this.item) {
        this.itemData = Object.assign({}, this.item)
      }

      this.changeDetected = null

      this.viewportWidth = window.innerWidth

      window.addEventListener('resize', this.onResize)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.onResize)
    },
    methods: {
      onResize() {
        this.viewportWidth = window.innerWidth
      },
      nl2br(str: string): string {
        if (typeof str !== 'undefined') {
          return str.replace(/(?:\r\n|\r|\n)/g, '<br />')
        } else {
          return ''
        }
      },
      updateNewContact(contact: Contact) {
        this.showAddContactModal = false
        this.newContact = contact
        if (this.newContactType !== '') {
          this.loadContact(contact?.id?.toString() ?? '', this.newContactType)
        }
        this.changeDetected = true
      },
      handleDropdown(command: string) {
        switch (command) {
          case 'infos':
            this.sivRequest()
            break

          case 'ng':
            this.dsvRequest()
            break

          case 'fiv':
            this.getFiv()
            break

          case 'dc':
            this.getDc('send')
            break

          case 'dc_edit':
            this.getDc('edit')
            break

          case 'clear':
            this.clearSiv()
            break
        }
      },
      submit() {
        if (this.changeDetected === true) {
          this.showNotification = false

          // Checking for vehicule plate number
          // if (
          //   this.itemData.immatriculation &&
          //   this.itemData.immatriculation !== '' &&
          //   !this.itemData.immatriculation?.match(/[a-z]{2}-[0-9]{3}-[a-z]{2}/gi)
          // ) {
          //   this.showNotification = true
          //   this.notification = {
          //     message: 'Erreur SIV : Merci de préciser une immatriculation au format AB-123-CD.',
          //     type: 'error',
          //   }

          //   return false
          // }

          const data = {
            immatriculation: this.itemData.immatriculation ?? null,
            infoimmat: this.itemData.infoimmat == '' ? null : this.itemData.infoimmat,
            gage: this.itemData.gage ?? null,
            vin: this.itemData.vin ?? null,
            previousOwner: this.itemData.previousOwnerId ?? null,
            newOwner: this.itemData.newOwnerId ?? null,
            ciPresent: this.itemData.ciPresent ?? null,
            ciDate: this.itemData.ciDate ?? null,
            opositionRight: this.itemData.opositionRight ?? null,
            numFormule: this.itemData.numFormule ?? null,
            cessionDate: this.itemData.cessionDate ?? null,
            rgaNumber: this.itemData.rgaNumber ?? null,
          }

          if (this.item) {
            // Edit
            this.busy = true
            this.$api
              .put(`/requisition/${this.itemData.requisition?.id}/item/${this.item.id}`, data)
              .then((response) => {
                const apiResponse = response.data as ApiResponse

                this.$notify({
                  type: 'success',
                  title: 'Succès',
                  message: 'Lot modifié avec succès !',
                })

                this.itemData = apiResponse.data
                this.$emit('itemUpdated', apiResponse.data.updatedItem)
                if (this.itemData.requisition) {
                  this.$emit('projectUpdated', this.itemData.requisition.project as RequisitionProject)
                }
              })
              .finally(() => {
                this.busy = false
                this.changeDetected = false
              })
          }
        }
      },
      resetForm() {
        this.itemData = {
          immatriculation: null,
          vin: null,
          infoimmat: '',
          gage: null,
          opositionRight: null,
          numFormule: null,
          ciPresent: null,
          ciDate: null,
        }

        this.changeDetected = false
      },
      cancelForm() {
        this.$router.push({
          name: 'requisition_item_edit',
          params: {
            itemId: this.itemData.requisition?.id?.toString() ?? '',
          },
        })
      },
      updateContactsList(contactsList: Record<string, string | null>[], contact: Contact) {
        contactsList.length = 0
        contactsList.push({
          label: contact.formName ?? '-',
          value: contact.id?.toString() ?? null,
        })
      },
      updatePreviousOwner(contact: Contact) {
        this.updateContactsList(this.previousOwners, contact)
        this.itemData.previousOwnerId = null
        this.previousOwnerInfos = this.setOwnerInfos(contact, 'previousOwner')
        this.$nextTick(() => {
          this.itemData.previousOwnerId = contact.id?.toString() ?? null
          this.submit()
        })
      },
      updateNewOwner(contact: Contact) {
        this.updateContactsList(this.newOwners, contact)
        this.itemData.newOwnerId = null
        this.newOwnerInfos = this.setOwnerInfos(contact, 'newOwner')
        this.$nextTick(() => {
          this.itemData.newOwnerId = contact.id?.toString() ?? null
          this.submit()
        })
      },
      loadContact(id: string, type: string) {
        if (id === 'add') {
          this.openContactForm(type)
        } else {
          this.loadContactInfos(id, type)
        }
      },
      loadContactInfos(id: string, type: string) {
        if (id == '') {
          if (type === 'previousOwner') {
            this.previousOwnerInfos = this.setOwnerInfos(null, type)
          } else if (type === 'requerant') {
            this.newOwnerInfos = this.setOwnerInfos(null, type)
          }
          return
        }
        this.busy = true
        this.$api
          .get(`/contact/${id}`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse
            if (type === 'previousOwner') {
              this.previousOwnerInfos = this.setOwnerInfos(apiResponse.data, type)
            } else if (type === 'newOwner') {
              this.newOwnerInfos = this.setOwnerInfos(apiResponse.data, type)
            }
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
          .finally(() => {
            this.busy = false
          })
      },
      setOwnerInfos(contact: Contact | null, type: string) {
        let address = '-'
        if (contact?.address != null) {
          address = `${contact?.address ?? ''} ${contact?.address2 ?? ''} ${contact?.postalCode ?? ''} ${
            contact?.city ?? ''
          }`
        }

        this.setBusinessInfos(contact, type)

        return {
          siret: contact?.siret ?? '-',
          vat: contact?.vat ?? '-',
          fullName: contact?.fullName ?? '-',
          formName: contact?.formName ?? '-',
          address,
          email: contact?.email ?? '-',
          phone: contact?.phone ?? '-',
          mobile: contact?.mobile ?? '-',
          companyName: contact?.companyName ?? '-',
        }
      },
      setBusinessInfos(contact: Contact | null, type: string) {
        if (type === 'previousOwner') {
          this.showBusinessPreviousInfos = contact?.type == 'personne_morale'
        } else if (type == 'newOwner') {
          this.showBusinessNewInfos = contact?.type == 'personne_morale'
        }
      },
      closeContactForm() {
        this.showAddContactModal = false
        if (this.newContactType === 'previousOwner') {
          this.itemData.previousOwnerId = null
        } else if (this.newContactType === 'newOwner') {
          this.itemData.newOwnerId = null
        }
      },
      // isTmsAccessTokenValid() {
      //   const accessToken = localStorage.getItem('tms_access_token')
      //   const accessTokenValidity = Number.parseInt(localStorage.getItem('tms_access_token_validity') ?? '0')
      //   const now = Math.round(Date.now() / 1000)

      //   if (!accessToken || !accessTokenValidity || accessTokenValidity < now) {
      //     return false
      //   } else {
      //     return true
      //   }
      // },
      // getTmsToken() {
      //   return new Promise((resolve) => {
      //     if (!this.isTmsAccessTokenValid()) {
      //       this.$api
      //         .post('/tms/access_token', {
      //           itemId: this.$route.params.itemId,
      //           tmsPassword: this.tmsPassword,
      //         })
      //         .then((response) => {
      //           const apiResponse = response.data as ApiResponse

      //           localStorage.setItem('tms_access_token', apiResponse.data.access_token)
      //           localStorage.setItem('tms_access_token_validity', apiResponse.data.access_token_validity)

      //           resolve(localStorage.getItem('tms_access_token'))
      //         })
      //         .catch((error) => {
      //           let errorMsg = ''
      //           if (error.response?.data?.notification?.message) {
      //             errorMsg = `<strong>${error.response.data.notification.message}</strong><br><br>`
      //           }
      //           this.$prompt(
      //             `${errorMsg}Merci de renseigner votre mot de passe TMS pour continuer`,
      //             'Votre mot de passe TMS',
      //             {
      //               confirmButtonText: 'OK',
      //               cancelButtonText: 'Annuler',
      //               inputType: 'password',
      //               dangerouslyUseHTMLString: true,
      //             }
      //           ).then((res) => {
      //             const value = (res as MessageBoxInputData).value
      //             this.tmsPassword = value

      //             resolve(this.getTmsToken())
      //           })
      //         })
      //     } else {
      //       resolve(localStorage.getItem('tms_access_token'))
      //     }
      //   })
      // },
      sivRequest() {
        //if (this.isValidRegistration(this.itemData.immatriculation ?? '-') || this.itemData.vin?.length == 17) {
        if (this.itemData.vin || this.itemData.immatriculation) {
          this.$confirm('Merci de confirmer la demande des informations du véhicule.', 'Confirmation', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Annuler',
            type: 'warning',
          }).then(() => {
            //this.getTmsToken().then(() => {
              //this.showNotification = false
              this.busy = true
              this.$api
                .post('/tms/infosvehicule', {
                  //tmsAccessToken: localStorage.getItem('tms_access_token') ?? null,
                  requisitionItem: this.itemData.id,
                  immatriculation: this.itemData.immatriculation,
                  vin: this.itemData.vin,
                  password: this.tmsPassword
                })
                .then((response) => {
                  const apiResponse = response.data as ApiResponse

                  const sivData = apiResponse.data

                  this.itemData.infoimmat = `N° d'immatriculation : ${sivData.immat ?? '-'}\nN˚ série type : ${
                    sivData.vin ?? '-'
                  }\nDMEC : ${this.formatDate(sivData.datemec, true)}\nCouleur : ${
                    sivData.couleur ?? '-'
                  }\nPuissance (ch) : ${sivData.puissch ?? '-'}\nPuissance fiscale : ${sivData.puissfisc ?? '-'}`

                  // Updating form infos
                  if (sivData.immat !== null) {
                    this.itemData.immatriculation = sivData.immat
                  }

                  if (sivData.vin !== null) {
                    this.itemData.vin = sivData.vin
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    if (error.response.status == 500) {
                      this.$notify({
                        type: 'error',
                        title: 'Erreur',
                        message: 'Le serveur TMS est indisponible. Merci de réessayer ultérieurement.',
                      })
                    }
                    else {
                      this.$prompt('Session TMS expirée. Merci de renseigner votre mot de passe TMS puis de lancer à nouveau la requête', 'Votre mot de passe TMS', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Annuler',
                      }).then((res) => {
                        const value = (res as MessageBoxInputData).value
                        this.tmsPassword = value
                      })                      
                    }
                  }
                })
                .finally(() => {
                  this.busy = false
                  this.$nextTick(() => {
                    this.submit()
                  })
                })
            //})
          })
        } else {
          //this.showNotification = true
          //this.notification = {
          //  message: "Merci de préciser un numéro d'immatriculation.",
          //  type: 'error',
          //}
          this.$notify({
              type: 'error',
              title: 'Erreur',
              message: "Merci de préciser un numéro VIN ou une immatriculation.",
            })
        }
      },
      isValidRegistration(str: string) {
        return str ? str.match(/([a-z]{2}|[0-9]{3,4})(-?| ?)([0-9]{3}|[a-z]{2,3})(-?| ?)([a-z]{2}|[0-9]{2})/gi) : false
      },
      isValidVin(str: string) {
        if (typeof str == 'undefined') {
          return false
        }

        return str !== null && str.length == 17
      },
      clearSiv() {
        this.$confirm(
          'Êtes-vous sûr(e) de vouloir supprimer les informations concernant le SIV ? Attention, cette opération est irréversible.',
          'Confirmation',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Annuler',
            type: 'warning',
          }
        ).then(() => {
          this.itemData.immatriculation = null
          this.itemData.infoimmat = null
          this.itemData.gage = null
          this.itemData.vin = null
          this.itemData.previousOwner = null
          this.itemData.previousOwnerId = null
          this.itemData.newOwner = null
          this.itemData.newOwnerId = null
          this.itemData.opositionRight = null
          this.itemData.ciPresent = null
          this.itemData.ciDate = null
          this.itemData.numFormule = null
          this.itemData.cessionDate = null
          this.itemData.declarationCession = null
          this.itemData.fiv = null

          this.$nextTick(() => {
            this.submit()
          })
        })
      },
      checkRequiredFormItems(itemsToCheck: ItemToCheck[]) {
        let failedItems = [] as string[]

        itemsToCheck.forEach((item) => {
          if (this.isNullOrEmpty(item.item)) {
            failedItems.push(item.name)
          }
        })

        if (failedItems.length > 0) {
          this.showNotification = true

          let message = 'Merci de remplir tous les champs nécessaires à votre demande :<br><br>'

          failedItems.forEach((failedItem) => {
            message += `- ${failedItem}\n`
          })

          this.notification = {
            message,
            type: 'error',
          }
        }
      },
      dsvRequest() {
        this.showNotification = false

        let itemsToCheck = [
          { item: this.itemData.immatriculation, name: 'Immatriculation' },
          { item: this.itemData.previousOwnerId, name: 'Ancien titulaire' },
        ]

        this.checkRequiredFormItems(itemsToCheck)

        if (this.showNotification == false) {
          this.$confirm('Merci de confirmer la demande de situation du véhicule.', 'Confirmation', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Annuler',
            type: 'warning',
          }).then(() => {
//            this.getTmsToken().then(() => {
              this.showNotification = false
              this.busy = true
              this.$api
                .post('/tms/dsv', {
                  tmsAccessToken: localStorage.getItem('tms_access_token') ?? null,
                  requisitionItem: this.itemData.id,
                  immatriculation: this.itemData.immatriculation,
                  vin: this.itemData.vin,
                  previousOwner: this.itemData.previousOwnerId,
                  password: this.tmsPassword,
                })
                .then((response) => {
                  const apiResponse = response.data as ApiResponse

                  if (apiResponse.data == null) {
                    this.$notify({
                      type: 'error',
                      title: 'Erreur',
                      message:
                        'Erreur: Impossible de récupérer la situation du véhicule. Merci de reéssayer plus tard.',
                    })
                  }

                  this.itemData.gage = apiResponse.data
                })
                .catch((error) => {
                  if (error.response) {
                    if (error.response.status == 500) {
                      this.$notify({
                        type: 'error',
                        title: 'Erreur',
                        message: 'Le serveur TMS est indisponible. Merci de réessayer ultérieurement.',
                      })
                    }
                    else {
                      this.$prompt('Session TMS expirée. Merci de renseigner votre mot de passe TMS puis de lancer à nouveau la requête', 'Votre mot de passe TMS', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Annuler',
                      }).then((res) => {
                        const value = (res as MessageBoxInputData).value
                        this.tmsPassword = value
                      })                      
                    }
                  }
                })
                .finally(() => {
                  this.busy = false
                  this.showNotification = false

                  this.$nextTick(() => {
                    this.submit()
                  })
                })
            })
          //})
        }
      },
      isNullOrEmpty(str: string | null | boolean | undefined) {
        if (typeof str === 'undefined') {
          return false
        }

        return str === null || str === ''
      },
      getDc(method: string) {
        this.showNotification = false

        let itemsToCheck = [
          { item: this.itemData.immatriculation, name: 'Immatriculation' },
          { item: this.itemData.vin, name: 'VIN' },
          { item: this.itemData.ciDate, name: "Date du certificat d'immatriculation" },
          { item: this.itemData.ciPresent, name: 'Possession de la carte grise (oui / non)' },
          { item: this.itemData.numFormule, name: 'Numéro de formule' },
          //{ item: this.itemData.opositionRight, name: "Droit d'opposition" },
          { item: this.itemData.previousOwnerId, name: 'Ancien titulaire' },
          { item: this.itemData.newOwnerId, name: 'Nouveau titulaire' },
          { item: this.itemData.cessionDate, name: 'Date de cession' },
        ]

        let failedItems = [] as string[]

        itemsToCheck.forEach((item) => {
          if (this.isNullOrEmpty(item.item)) {
            failedItems.push(item.name)
          }
        })

        if (failedItems.length > 0) {
          this.showNotification = true

          let message = 'Merci de remplir tous les champs nécessaires à votre demande :<br><br>'

          failedItems.forEach((failedItem) => {
            message += `- ${failedItem}\n`
          })

          this.notification = {
            message,
            type: 'error',
          }
        }

        if (this.showNotification == false) {
          this.$confirm('Merci de confirmer la demande de Déclaration de Cession.', 'Confirmation', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Annuler',
            type: 'warning',
          }).then(() => {
            const data = {
              //tmsAccessToken: localStorage.getItem('tms_access_token') ?? null,
              requisitionItem: this.itemData.id,
              immatriculation: this.itemData.immatriculation,
              vin: this.itemData.vin,
              ciPresent: this.itemData.ciPresent,
              ciDate: this.itemData.ciDate,
              previousOwner: this.itemData.previousOwnerId,
              newOwner: this.itemData.newOwnerId,
              opositionRight: this.itemData.opositionRight,
              numFormule: this.itemData.numFormule,
              cessionDate: this.itemData.cessionDate,
              password: this.tmsPassword,
              method: method,
            }
            this.busy = true
            this.$api
              .post('/tms/dc', data)
              .then(() => {
                this.$notify({
                  type: 'success',
                  title: 'Succès',
                  message:
                    'La Déclaration de Cession a été récupérée avec succès. Vous la retrouverez dans la liste des documents de votre réquisition.',
                })
              })
              .catch((error) => {
                if (error.response) {
                    if (error.response.status == 500) {
                      this.$notify({
                        type: 'error',
                        title: 'Erreur',
                        message: 'Le serveur TMS est indisponible. Merci de réessayer ultérieurement.',
                      })
                    }
                    else {
                      this.$prompt('Session TMS expirée. Merci de renseigner votre mot de passe TMS puis de lancer à nouveau la requête', 'Votre mot de passe TMS', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Annuler',
                      }).then((res) => {
                        const value = (res as MessageBoxInputData).value
                        this.tmsPassword = value
                      })                      
                    }
                  }
              })
              .finally(() => {
                this.busy = false
                this.showNotification = false
                this.submit()
              })
          })
        }
      },
      getFiv() {
        this.showNotification = false
        let itemsToCheck = [
          { item: this.itemData.immatriculation, name: 'Immatriculation' },
          { item: this.itemData.vin, name: 'VIN' },
          //{ item: this.itemData.ciDate, name: 'Date de la carte grise' },
          //{ item: this.itemData.ciPresent, name: 'Présence de la carte grise (oui / non)' },
          //{ item: this.itemData.numFormule, name: 'Numéro de formule' },
          { item: this.itemData.opositionRight, name: "Droit d'opposition" },
          { item: this.itemData.previousOwnerId, name: 'Ancien titulaire' },
          //{ item: this.itemData.newOwnerId, name: 'Nouveau titulaire' },
        ]

        this.checkRequiredFormItems(itemsToCheck)

        if (this.showNotification == false) {
          //this.getTmsToken().then(() => {
            //this.showNotification = false

            this.$confirm("Merci de confirmer la demande de Fiche d'Identification du Véhicule.", 'Confirmation', {
              confirmButtonText: 'OK',
              cancelButtonText: 'Annuler',
              type: 'warning',
            }).then(() => {
              const data = {
                //tmsAccessToken: localStorage.getItem('tms_access_token') ?? null,
                requisitionItem: this.itemData.id,
                immatriculation: this.itemData.immatriculation,
                vin: this.itemData.vin,
                //ciPresent: this.itemData.ciPresent,
                ciDate: this.itemData.ciDate,
                previousOwner: this.itemData.previousOwnerId,
                //newOwner: this.itemData.newOwnerId,
                opositionRight: this.itemData.opositionRight,
                numFormule: this.itemData.numFormule,
                password: this.tmsPassword
              }
              this.busy = true
              this.$api
                .post('/tms/fiv', data)
                .then(() => {
                  this.$notify({
                    type: 'success',
                    title: 'Succès',
                    message:
                      "La Fiche d'Identification du Véhicule a été récupérée avec succès. Vous la retrouverez dans la liste des documents de votre réquisition.",
                  })
                })
                .catch((error) => {
                  if (error.response) {
                    if (error.response.status == 500) {
                      this.$notify({
                        type: 'error',
                        title: 'Erreur',
                        message: 'Le serveur TMS est indisponible. Merci de réessayer ultérieurement.',
                      })
                    }
                    else {
                      this.$prompt('Session TMS expirée. Merci de renseigner votre mot de passe TMS puis de lancer à nouveau la requête', 'Votre mot de passe TMS', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Annuler',
                      }).then((res) => {
                        const value = (res as MessageBoxInputData).value
                        this.tmsPassword = value
                      })                      
                    }
                  }
                })
                .finally(() => {
                  this.submit()
                })
            })
          //})
        }
      },
      download(format: string, token: string) {
        let url = ''

        if (format == 'pdf') {
          url = `${process.env.VUE_APP_API_URL}/document/${token}/download?contentd=inline`
        } else {
          url = `${process.env.VUE_APP_API_URL}/document/${token}/download`
        }

        window.open(url, '_blank')
      },
      searchPreviousOwners(search?: string) {
        this.previousOwners = this.searchContacts(search)
      },
      searchNewOwners(search?: string) {
        this.newOwners = this.searchContacts(search)
      },
      openContactForm(type: string, contactId?: string) {
        this.clearNewContact()
        if (!this.busy) {
          this.showAddContactModal = true
          this.newContactType = type
          this.newContactId = contactId ?? null
        }
      },
      searchContacts(
        search?: string,
        category = null as string[] | null,
        minLength = 2
      ): Record<string, string | null>[] {
        if (search == '*' || (search && search.length >= minLength)) {
          if (search == '*') {
            search = ''
          }

          this.busy = true
          let contacts = [] as Record<string, string | null>[]
          this.$api
            .get('/contacts', {
              params: {
                search: search ?? null,
                category,
              },
            })
            .then((response) => {
              const apiResponse = response.data as ApiResponse
              if (apiResponse.data && Array.isArray(apiResponse.data)) {
                apiResponse.data.forEach((contact: Contact) => {
                  let contactLabel = contact.formName
                  if (contact.city) {
                    contactLabel = `${contactLabel} - ${contact.city}`
                  }
                  contacts.push({
                    label: contactLabel ?? null,
                    value: contact.id?.toString() ?? null,
                  })
                })
              }
            })
            .finally(() => {
              this.busy = false
            })

          return contacts
        } else {
          return []
        }
      },
      clearNewContact() {
        this.newContactId = null
        this.resetContactForm = !this.resetContactForm
        this.changeDetected = true
      },
      formatDate(str: string, short = false): string {
        const date = Date.parse(str)
        if (!isNaN(date)) {
          if (short === true) {
            return new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium' }).format(date)
          } else {
            return new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium', timeStyle: 'short' }).format(date)
          }
        } else {
          return '-'
        }
      },
      goToDocs() {
        this.$emit('goToDocs', 'documents')
      },
    },
  })
