






























































































































































































































































































































































import { ApiResponse, ChecklistItem, RequisitionItem, Vehicle } from '@/interfaces'
import Vue from 'vue'
import CarFront from '@/components/CarFront.vue'
import CarBack from '@/components/CarBack.vue'
import { MessageBoxInputData } from 'element-ui/types/message-box'
import {
  failures,
  equipments,
  vehicle,
  vehicleBrands,
  vehicleGenres,
  vehicleFuelTypes,
  vehicleStates,
  checklist,
} from '@/formHelpers'

interface Notification {
  message: string
  type: 'error' | 'success'
}

interface VehicleVersion {
  finish: string
  generation: null | string
  id: number
  mcclbp: boolean
  name: string
  observed: boolean
  preferred: boolean
  ref: boolean
  type: string
}

interface SentItem {
  id: number
  vehicle: Vehicle
  description?: string
  rubriqueKey?: string
}

export default Vue.extend({
  components: { CarFront, CarBack },
  props: {
    item: {
      type: Object as () => RequisitionItem,
      required: true,
    },
  },
  data() {
    return {
      busy: false,
      viewportWidth: 0,
      failures,
      equipments,
      vehicle,
      vehicleBrands,
      vehicleGenres,
      vehicleFuelTypes,
      vehicleStates,
      tmsPassword: null as null | string,
      showNotification: false,
      notification: {
        message: '',
        type: 'success',
      } as Notification,
      versions: [] as VehicleVersion[],
      showVersionsModal: false,
    }
  },
  watch: {
    item: {
      deep: true,
      immediate: true,
      handler(newVal) {
        // eslint-disable-next-line no-console
        console.log("item watch " + this.busy)

        this.vehicle = newVal.vehicle ?? vehicle

        if (newVal.vehicle?.checklist && newVal.vehicle?.checklist.length == 0) {
          this.vehicle.checklist = checklist
        }

        this.updateCarPartsColors(newVal)
      },
    },
  },
  mounted() {
    this.viewportWidth = window.innerWidth

    window.addEventListener('resize', this.onResize)

    this.updateCarPartsColors(this.item)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.viewportWidth = window.innerWidth
    },
    cancel() {
      this.$router.push({
        name: 'requisition_edit',
        params: {
          requisitionId: this.item.requisition?.id?.toString() ?? '',
        },
      })
    },
    submit() {
      // eslint-disable-next-line no-console
      console.log("submit " + this.busy)
      if (this.busy) {
        return
      }
      this.busy = true

      // Resetting versionid
      if (this.isEmpty(this.vehicle.commercialType)) {
        this.vehicle.versionId = null
      }

      let item = { id: this.item.id, vehicle: { ...this.vehicle } } as SentItem

      // Filling item description
      let description = ''
      if (this.isEmpty(this.item.description)) {
        description = !this.isEmpty(this.vehicle.genre) ? `${this.vehicle.genre}` : ''
        description += !this.isEmpty(this.vehicle.brand) ? ` - ${this.vehicle.brand}` : ''
        description += !this.isEmpty(this.vehicle.commercialType) ? ` ${this.vehicle.commercialType}` : ''
        description += !this.isEmpty(this.vehicle.dmec) ? ` - Dmec : ${this.formatDate(this.vehicle.dmec)}` : ''
        description += !this.isEmpty(this.vehicle.km) ? ` - ${this.vehicle.km}km` : ''
        description += !this.isEmpty(this.vehicle.body) ? ` - Carrosserie : ${this.vehicle.body}` : ''
        description += !this.isEmpty(this.vehicle.fuelType) ? ` - Énergie : ${this.vehicle.fuelType}` : ''

        if (this.vehicle.failures && this.vehicle.failures.length > 0) {
          description += ` - Observations :`

          this.vehicle.failures.forEach((failure, index) => {
            description += ` ${failure}`

            if (index !== this.vehicle.failures?.length) {
              description += `,`
            } else {
              description += `.`
            }
          })
        }

        if (this.vehicle.equipments && this.vehicle.equipments.length > 0) {
          description += ` - Équipements :`

          this.vehicle.equipments.forEach((equipment, index) => {
            description += ` ${equipment}`

            if (index !== this.vehicle.equipments?.length) {
              description += ` /`
            } else {
              description += `.`
            }
          })
        }

        if (!this.isEmpty(this.vehicle.carteGriseReceptionDate)) {
          description += ` - CARTE GRISE DISPONIBLE`
        }

        item.description = description
      }

      // Updating item category if need be
      if (this.item.rubriqueKey == null && this.vehicle.genre == 'VP') {
        item.rubriqueKey = '262'
      }

      this.$api
        .put(`/requisition/${this.item.requisition?.id ?? null}/item/${this.item.id}`, item)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          // provoque des erreurs
          //this.vehicle = apiResponse.data.vehicle
          // voir si utile si oui c'est ce code
          //this.vehicle = apiResponse.data.updatedItem.vehicle

          // eslint-disable-next-line no-console
          console.log("updated " + this.busy)

          // Refresh item if need be : s'il y a une description contruite en locale et donc envoyée au serveur
          // il faut recharger la fiche principale
          if (description.length > 0 || (item.rubriqueKey && item.rubriqueKey.length > 0)) {
            this.$emit('itemUpdated', apiResponse.data.updatedItem)
          }
        })
        .finally(() => {
          this.busy = false

          // eslint-disable-next-line no-console
          console.log("updated " + this.busy)

        })
    },
    handleDropdown(command: string) {
      switch (command) {
        case 'infos':
          this.sivRequest()
          break

        case 'autobiz_details':
          this.autobizDetailsRequest()
          break

        case 'autobiz_valuation':
          this.autobizValuationRequest()
          break

        case 'under_construction':
          this.$alert('Fonctionnalité en cours de développement.', 'Info', { confirmButtonText: 'OK' })
          break
      }
    },
    nl2br(str: string): string {
      if (typeof str !== 'undefined') {
        return str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      } else {
        return ''
      }
    },
    isTmsAccessTokenValid() {
      const accessToken = localStorage.getItem('tms_access_token')
      const accessTokenValidity = Number.parseInt(localStorage.getItem('tms_access_token_validity') ?? '0')
      const now = Math.round(Date.now() / 1000)

      if (!accessToken || !accessTokenValidity || accessTokenValidity < now) {
        return false
      } else {
        return true
      }
    },
    // getTmsToken() {
    //   return new Promise((resolve) => {
    //     if (!this.isTmsAccessTokenValid()) {
    //       this.$api
    //         .post('/tms/access_token', {
    //           itemId: this.$route.params.itemId,
    //           tmsPassword: this.tmsPassword,
    //         })
    //         .then((response) => {
    //           const apiResponse = response.data as ApiResponse

    //           localStorage.setItem('tms_access_token', apiResponse.data.access_token)
    //           localStorage.setItem('tms_access_token_validity', apiResponse.data.access_token_validity)

    //           resolve(localStorage.getItem('tms_access_token'))
    //         })
    //         .catch(() => {
    //           this.$prompt('Merci de renseigner votre mot de passe TMS pour continuer', 'Votre mot de passe TMS', {
    //             confirmButtonText: 'OK',
    //             cancelButtonText: 'Annuler',
    //           }).then((res) => {
    //             const value = (res as MessageBoxInputData).value
    //             this.tmsPassword = value

    //             resolve(this.getTmsToken())
    //           })
    //         })
    //     } else {
    //       resolve(localStorage.getItem('tms_access_token'))
    //     }
    //   })
    // },
    sivRequest() {
      //if (this.isValidSiv(this.vehicle.immatriculation ?? '-') || this.vehicle.vin?.length == 17) {
      if (this.vehicle.immatriculation || this.vehicle.vin) {
        this.$confirm('Merci de confirmer la demande des informations du véhicule.', 'Confirmation', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }).then(() => {
          // this.getTmsToken().then(() => {
          //   this.showNotification = false
            this.busy = true
            this.$api
              .post('/tms/infosvehicule', {
                //tmsAccessToken: localStorage.getItem('tms_access_token') ?? null,
                password: this.tmsPassword,
                requisitionItem: this.item.id,
                immatriculation: this.vehicle.immatriculation,
                vin: this.vehicle.vin,
              })
              .then((response) => {
                const apiResponse = response.data as ApiResponse

                const sivData = apiResponse.data

                // Filling infos from API, if not present
                this.vehicle.color = this.isEmpty(this.vehicle.color) ? sivData.couleur : this.vehicle.color
                this.vehicle.fuelType = this.isEmpty(this.vehicle.fuelType)
                  ? sivData.energie.toString().toLowerCase()
                  : this.vehicle.fuelType
                this.vehicle.immatriculation = this.isEmpty(this.vehicle.immatriculation)
                  ? sivData.immat
                  : this.vehicle.immatriculation
                this.vehicle.powerKw = this.isEmpty(this.vehicle.powerKw)
                  ? Math.round(parseInt(sivData.puissch) * 1.36).toString()
                  : this.vehicle.powerKw
                this.vehicle.power = this.isEmpty(this.vehicle.power) ? sivData.puissfisc : this.vehicle.power
                this.vehicle.vin = this.isEmpty(this.vehicle.vin) ? sivData.vin : this.vehicle.vin
                this.vehicle.genre = this.isEmpty(this.vehicle.genre) ? sivData.genre : this.vehicle.genre
                this.vehicle.brand = this.isEmpty(this.vehicle.brand) ? sivData.brand : this.vehicle.brand
                this.vehicle.year = this.isEmpty(this.vehicle.year) ? this.getYear(sivData.dmec) : this.vehicle.year
                this.vehicle.dmec = this.isEmpty(this.vehicle.dmec) ? sivData.dmec : this.vehicle.dmec
                this.vehicle.fuelType = this.isEmpty(this.vehicle.fuelType) ? sivData.fuelType : this.vehicle.fuelType
                this.vehicle.mineType = this.isEmpty(this.vehicle.mineType) ? sivData.mineType : this.vehicle.mineType
                this.vehicle.body = this.isEmpty(this.vehicle.body) ? sivData.body : this.vehicle.body
                this.vehicle.seats = this.isEmpty(this.vehicle.seats) ? sivData.seats : this.vehicle.seats

                // Saving item
                this.submit()
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.status == 500) {
                    this.$notify({
                      type: 'error',
                      title: 'Erreur',
                      message: 'Le serveur TMS est indisponible. Merci de réessayer ultérieurement.',
                    })
                  }
                  else {
                    this.$prompt('Session TMS expirée. Merci de renseigner votre mot de passe TMS puis de lancer à nouveau la requête', 'Votre mot de passe TMS', {
                      confirmButtonText: 'OK',
                      cancelButtonText: 'Annuler',
                    }).then((res) => {
                      const value = (res as MessageBoxInputData).value
                      this.tmsPassword = value
                    })                      
                  }
                }
              })
              .finally(() => {
                this.busy = false
                this.$nextTick(() => {
                  this.submit()
                })
              })
          //})
        })
      } else {
        // this.showNotification = true
        // this.notification = {
        //   message: "Merci de préciser un numéro d'immatriculation.",
        //   type: 'error',
        // }
        this.$notify({
            type: 'error',
            title: 'Erreur',
            message: "Merci de préciser un numéro VIN ou une immatriculation.",
          })
      }
    },
    isEmpty(str: null | string | number | undefined) {
      return str == null || str.toString().length == 0
    },
    // isValidSiv(str: string) {
    //   return str ? str.match(/[a-z]{2}-[0-9]{3}-[a-z]{2}/gi) : false
    // },
    getTodayISO() {
      const date = new Date()
      const yyyy = date.getFullYear().toString()
      const mm = (date.getMonth() + 1).toString()
      const dd = date.getDate().toString()
      const mmChars = mm.split('')
      const ddChars = dd.split('')

      return yyyy + '-' + (mmChars[1] ? mm : '0' + mmChars[0]) + '-' + (ddChars[1] ? dd : '0' + ddChars[0])
    },
    updateChecklist(checklistItem: ChecklistItem) {
      this.vehicle.checklist.forEach((item) => {
        if (item.step === checklistItem.step) {
          if (checklistItem.checked === false) {
            item.date = null
          } else {
            item.date = this.getTodayISO()
          }

          this.submit()
        }
      })
    },
    formatDate(str: string | null): string {
      if (str !== null) {
        const date = Date.parse(str)
        if (!isNaN(date)) {
          return new Intl.DateTimeFormat('fr-FR', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          }).format(date)
        }
      }

      return '-'
    },
    updateItem(item: RequisitionItem) {
      if (item.vehicle) {
        this.vehicle = item.vehicle

        this.$emit('itemUpdated', item)
      }
    },
    autobizDetailsRequest() {
      if (!this.isValidRegistration(this.vehicle.immatriculation ?? '')) {
        this.$notify({
          type: 'error',
          title: 'Erreur',
          message: "Merci de renseigner l'immatriculation du véhicule",
        })
      } else {
        this.busy = true
        this.$api
          .post('/autobiz/get_details', {
            registration: this.vehicle.immatriculation,
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            if (apiResponse.data.status == false) {
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message: "Le numéro de plaque d'immatriculation indiqué ne retourne aucun résultat.",
              })
            } else {
              this.versions = apiResponse.data.versions

              if (this.vehicle.versionId == null) {
                this.showVersionsModal = true
              } else {
                const autobizData = apiResponse.data

                // Filling infos from API, if not present
                this.versions.forEach((version) => {
                  if (version.id.toString() == this.vehicle.versionId?.toString()) {
                    this.vehicle.commercialType = `${autobizData.modelName} ${version.name}`
                  }
                })
                this.vehicle.color = this.isEmpty(this.vehicle.color) ? autobizData.color : this.vehicle.color
                this.vehicle.dmec = this.isEmpty(this.vehicle.dmec) ? autobizData.dateRelease : this.vehicle.dmec
                this.vehicle.fuelType = this.isEmpty(this.vehicle.fuelType)
                  ? autobizData.fuelName.toString().toLowerCase()
                  : this.vehicle.fuelType
                this.vehicle.powerKw = this.isEmpty(this.vehicle.powerKw)
                  ? autobizData.kw.toString()
                  : this.vehicle.powerKw
                this.vehicle.power = this.isEmpty(this.vehicle.power)
                  ? autobizData.fiscal.toString()
                  : this.vehicle.power
                this.vehicle.vin = this.isEmpty(this.vehicle.vin) ? autobizData.vin : this.vehicle.vin
                this.vehicle.genre = this.isEmpty(this.vehicle.genre)
                  ? autobizData.vehicleType.code
                  : this.vehicle.genre
                this.vehicle.brand = this.isEmpty(this.vehicle.brand) ? autobizData.makeName : this.vehicle.brand
                this.vehicle.year = this.isEmpty(this.vehicle.year)
                  ? this.getYear(autobizData.dateRelease)
                  : this.vehicle.year
                this.vehicle.fuelType = this.isEmpty(this.vehicle.fuelType)
                  ? autobizData.fuelName
                  : this.vehicle.fuelType
                this.vehicle.mineType = this.isEmpty(this.vehicle.mineType)
                  ? autobizData.typeMine
                  : this.vehicle.mineType
                this.vehicle.body = this.isEmpty(this.vehicle.body) ? autobizData.bodyName : this.vehicle.body
                this.vehicle.seats = this.isEmpty(this.vehicle.seats)
                  ? autobizData.seats.toString()
                  : this.vehicle.seats

                // Hiding modal
                this.showVersionsModal = false
              }
            }
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    autobizValuationRequest() {
      if (
        !this.isValidRegistration(this.vehicle.immatriculation ?? '') ||
        this.isEmpty(this.vehicle.year) ||
        this.isEmpty(this.vehicle.km)
      ) {
        this.$notify({
          type: 'error',
          title: 'Erreur',
          message: 'Merci de renseigner les champs obligatoires suivants : Immatriculation, Année, Kilométrage.',
        })
      } else {
        this.busy = true
        this.$api
          .post('/autobiz/get_valuation', {
            registration: this.vehicle.immatriculation,
            versionId: this.vehicle.versionId,
            year: this.vehicle.year,
            mileage: this.vehicle.km,
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            if (apiResponse.data.status == false) {
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message: "Le numéro de plaque d'immatriculation indiqué ne retourne aucun résultat.",
              })
            } else {
              if (this.vehicle.versionId == null) {
                this.$alert(
                  "Finition du véhicule manquante, merci d'effectuer une recherche d'information via Autobiz en premier.",
                  'Info',
                  { confirmButtonText: 'OK' }
                )
              } else {
                const autobizData = apiResponse.data

                // Feeling quotation
                if (autobizData._quotation) {
                  this.vehicle.marketValue = autobizData._quotation.b2cMarketValue.toString()
                }

                // Saving item
                this.submit()
              }
            }
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    isValidRegistration(str: string) {
      return str ? str.match(/([a-z]{2}|[0-9]{3,4})(-?| ?)([0-9]{3}|[a-z]{2,3})(-?| ?)([a-z]{2}|[0-9]{2})/gi) : false
    },
    updateCarPartsColors(item: RequisitionItem) {
      const carParts = document.querySelectorAll('.car-part')
      carParts.forEach((carPart) => {
        carPart.classList.remove('has-scratch')
        carPart.classList.remove('has-score')
        carPart.classList.remove('has-dent')
      })

      const defects = item.vehicle?.defects
      if (defects && defects.length > 0) {
        defects.forEach((defect) => {
          const el = document.getElementById(defect.part)

          if (el) {
            switch (defect.type) {
              case 1:
                el.classList.add('has-scratch')
                break

              case 2:
                el.classList.add('has-score')
                break

              case 3:
                el.classList.add('has-dent')
                break
            }
          }
        })
      }
    },
    getYear(str: string | null): string | null {
      let year = null as null | string

      if (str !== null) {
        const date = new Date(str)
        year = date.getFullYear().toString()
      }

      return year
    },
  },
})
